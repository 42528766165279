
// couldn't access styling within normal css, going into shadow dowm with js
document.addEventListener('DOMContentLoaded', function() {

  // style cart shadow button
  function styleCartButton(shadowButton) {

    const cart = document.querySelector('.vs-cart');
    const body = document.querySelector('body');

    // check to see if body has purple class
    if(body.classList.contains('accent-purple')) {
      cart.classList.add('dark');
    }

    // styling cart positioning
    shadowButton.style.position = 'absolute'; // could explore appending to heading wrap
    shadowButton.style.top = '20px';
    shadowButton.style.right = '14px';
    shadowButton.style.backgroundSize = '80%';
    shadowButton.style.backgroundRepeat = 'no-repeat';
    shadowButton.style.display = 'block';

    const cartContent = shadowButton.querySelector('.vs-cart-button-content');
    cartContent.style.position = 'relative';
    cartContent.style.width = '100%';
    cartContent.style.height = '100%';
    cartContent.style.paddingBottom = '0';

    // update qty text with old cart button
    const qtyWrapper = shadowButton.querySelector('.vs-cart-qty');
    qtyWrapper.style.position = 'absolute';
    qtyWrapper.style.top = '14px';
    qtyWrapper.style.left = '0';
    qtyWrapper.style.width = '100%';
    qtyWrapper.style.height = '100%';
    qtyWrapper.style.textAlign = 'center';

    // update qty text with old cart button
    const buttonContent = shadowButton.querySelector('.vs-cart-button-content-visible');
    buttonContent.style.fontSize = '10px';
  }

  // when a cart button is added to the DOM
  function handleCartButtonAdded(cartButtonComponent) {
    const shadowRoot = cartButtonComponent.shadowRoot;
    if (!shadowRoot) return;

    const shadowButton = shadowRoot.querySelector('.vs-cart-button');
    if (!shadowButton) return;

    styleCartButton(shadowButton);
    observer.disconnect(); // stop observer
  }

  // mutation observer, wait for vinoshipper markup to load
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type !== 'childList') return;

      const cartButtonComponent = document.querySelector('.vs-cart-button-stage');
      if (cartButtonComponent) {
          handleCartButtonAdded(cartButtonComponent);
      }
    });
  });

  const addToCartButton = document.querySelector('.vs-add-to-cart');
  if (addToCartButton) {
    addToCartButton.addEventListener('change', function(event) {
      if (event.target && event.target.matches('.vs-add-to-cart-qty')) {
        event.target.blur(); // Remove focus from the quantity select element
      }
    });
  }

  // start observing for childnodes added (not sure how heavy this would be)
  // update: disconnect observer when stopped
  observer.observe(document.body, {
      childList: true,
      subtree: true
  });

});
