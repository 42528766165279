// CSS
import "@css/main.pcss";

// adds class to body so we can enable/disable focus rings
// https://github.com/ten1seven/what-input
import "what-input";

// lazy image loading
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes/plugins/aspectratio/ls.aspectratio";
import "lazysizes";
import "@js/lib/vinoshipper.js";

import Alpine from 'alpinejs';
window.Alpine = Alpine
Alpine.start()

import ScrollOut from "scroll-out";

ScrollOut({
  scope: '#app',
  cssProps: {
    scrollPercentY: true,
    visibleY: true,
    viewportY: true
  }
});

let tockButtons = document.querySelectorAll(".tock-button");
for (let i = 0; i < tockButtons.length; i++) {
  tockButtons[i].setAttribute("data-tock-reserve", "true");
  tockButtons[i].setAttribute("data-tock-offering", "322744");
}
